import React, { useState } from 'react';
import './App.css';
import TextInput from './components/TextInput';
import AnalysisDisplay from './components/AnalysisDisplay';

function App() {
  const [analysisResult, setAnalysisResult] = useState(null);
  const [inputText, setInputText] = useState('');
  const [engine, setEngine] = useState('TextClassifier');

  const handleTextSubmit = async (text, selectedEngine) => {
    setInputText(text);
    setEngine(selectedEngine);

    // API call to the backend
    try {
      const response = await fetch('https://n15.truliacare.com/textanalyzerapi/analyze', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ payload: { text, engine: selectedEngine } })
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      setAnalysisResult(data.payload);
    } catch (error) {
      console.error('Error fetching analysis:', error);
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1>Clinician Response Analyzer</h1>
      </header>
      <main>
        <TextInput onSubmit={handleTextSubmit} />
        {analysisResult && (
          <AnalysisDisplay
            analysis={analysisResult}
            inputText={inputText}
            engine={engine}
          />
        )}
      </main>
    </div>
  );
}

export default App;
