import React from 'react';
import './AnalysisDisplay.css';

const categoriesColors = {
  "Energy Level": '#EFEFEF',    // Matte Red
  "Communication": '#EFEFEF',  // Matte Purple
  "Movement": '#EFEFEF',       // Matte Green
  "Incontinence": '#EFEFEF',   // Matte Pink
  "Food": '#EFEFEF',           // Matte Blue
  "Orientation": '#EFEFEF',    // Matte Brown
  "Breathing": '#EFEFEF',      // Matte Violet
  "Appearance": '#EFEFEF',     // Matte Teal
  "Other": '#EFEFEF'           // Matte Magenta
};

function AnalysisDisplay({ analysis, inputText, engine }) {
  console.log(engine);
  if (engine === 'FormSummarizer') {
    return (
      <div>
        <h2>Analysis Result</h2>
        <p>{analysis}</p>
      </div>
    );
  }

  return (
    <div>
      <h2>Analysis Result</h2>
      {Object.keys(analysis).map((category, index) => (
        <div key={index} className="category">
          <h3>{category}</h3>
          {analysis[category] ? (
            <p className="highlight" style={{ backgroundColor: categoriesColors[category] }}>
              {analysis[category]}
            </p>
          ) : (
            <p className="missing">Missing</p>
          )}
        </div>
      ))}
    </div>
  );
}

export default AnalysisDisplay;
