import React, { useState } from 'react';

function TextInput({ onSubmit }) {
  const [text, setText] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit(text);
  };

  return (
    <form onSubmit={handleSubmit}>
      <textarea
        value={text}
        onChange={(e) => setText(e.target.value)}
        placeholder="Type or paste your text here..."
        rows="10"
        cols="50"
      ></textarea>
      <br />
      <button type="submit">Submit</button>
    </form>
  );
}

export default TextInput;
